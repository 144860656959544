import { Button } from "@mui/material";
import { useState } from "react";
import { StatelessColors } from "../theme";

type DoubleConfirmProps = {
	dialog: [string, string, string];
	action: () => void;
};
export default function DoubleConfirmButton({
	dialog,
	action,
}: DoubleConfirmProps) {
	const timesRequired = 2;
	const [timesClicked, setTimesClicked] = useState(0);
	const btnColors = {
		first: {
			backgroundColor: StatelessColors.white,
			color: StatelessColors.primary,
			"&:hover": {
				backgroundColor: StatelessColors.white,
				color: StatelessColors.error,
				borderColor: StatelessColors.error,
			},
		},
		warn: {
			backgroundColor: StatelessColors.white,
			color: StatelessColors.error,
			borderColor: StatelessColors.error,
			"&:hover": {
				backgroundColor: StatelessColors.error,
				color: StatelessColors.white,
				borderColor: StatelessColors.error,
			},
		},
		done: {
			backgroundColor: StatelessColors.white,
			color: StatelessColors.primary,
			borderColor: StatelessColors.white,
			"&:hover": {
				backgroundColor: StatelessColors.white,
				color: StatelessColors.primary,
				borderColor: StatelessColors.white,
			},
		},
	};

	function onPress() {
		const clickCount = timesClicked + 1;
		if (clickCount === timesRequired) {
			action();
		}
		setTimesClicked(clickCount);
	}

	return (
		<Button
			variant="outlined"
			size="small"
			color="primary"
			sx={[
				timesClicked === 0 && btnColors.first,
				timesClicked === timesRequired - 1 && btnColors.warn,
				timesClicked === timesRequired && btnColors.done,
			]}
			disabled={timesClicked === timesRequired}
			onClick={onPress}
			onBlur={() => setTimesClicked(0)}
		>
			{dialog[timesClicked]}
		</Button>
	);
}
