import * as AIF from "@stateless/aif-api";
import { Zodios, mergeApis } from "@zodios/core";
import { ZodiosHooks } from "@zodios/react";
import axios from "axios";
import { useAuth } from "oidc-react";

export type Status = "idle" | "loading";

// Configure default axios
axios.defaults.baseURL = "/api/v1";
axios.defaults.paramsSerializer = { indexes: null };

export const ax = axios.create();

export const ocnApiClient = new Zodios(
	mergeApis({
		[AIF.OcnNetworks.rootUrl]: AIF.OcnNetworks.Spec,
		[AIF.Services.rootUrl]: AIF.Services.Spec,
		[AIF.Lookup.rootUrl]: AIF.Lookup.Spec,
		[AIF.Accounts.AwsExternal.rootUrl]: AIF.Accounts.AwsExternal.Spec,
		[AIF.Rbac.Organizations.rootUrl]: AIF.Rbac.Organizations.Spec,
		[AIF.FinishOnboard.rootUrl]: AIF.FinishOnboard.Spec,
	}),
	{ axiosInstance: ax },
);

export const useOcnHooks = () => {
	const auth = useAuth();
	const configuredInstance = axios.create({
		headers: { Authorization: `Bearer ${auth.userData?.access_token}` },
	});

	return new ZodiosHooks(
		"ocnApi",
		new Zodios(
			mergeApis({
				[AIF.OcnNetworks.rootUrl]: AIF.OcnNetworks.Spec,
				[AIF.Services.rootUrl]: AIF.Services.Spec,
				[AIF.Lookup.rootUrl]: AIF.Lookup.Spec,
				[AIF.Accounts.AwsExternal.rootUrl]: AIF.Accounts.AwsExternal.Spec,
				[AIF.Rbac.Organizations.rootUrl]: AIF.Rbac.Organizations.Spec,
			}),
			{
				axiosInstance: configuredInstance,
			},
		),
	);
};
