import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";

type ErrorToastProps = {
	showErrorToast: boolean;
	errorMessage: string | undefined;
};

export default function ErrorToast({ errorMessage }: ErrorToastProps) {
	const [open, setOpen] = useState(true);

	const handleClose = (
		_event: React.SyntheticEvent | Event,
		_reason?: string,
	) => {
		setOpen(false);
	};

	return (
		<>
			<Snackbar
				open={open && !!errorMessage}
				autoHideDuration={30000}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				onClose={handleClose}
			>
				<Alert severity="error" variant="filled">
					<Typography variant="body1" color="white">
						{errorMessage}
					</Typography>
				</Alert>
			</Snackbar>
		</>
	);
}
