import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ocnApiClient as aifApiClient, Status } from "../api";
import { OcnNetworks as AifNetworks } from "@stateless/aif-api";
import { RootState } from ".";
import { isAxiosError } from "axios";
import { NotSubscribedError } from "@stateless/aif-api/dist/ocnNetworks";
type Step = 0 | 1 | 2 | 3;
type AifCreationFormState = {
	status: Status;

	step: Step;

	error?: string;
	response?: AifNetworks.Get;

	// This is a ref to the account to use
	accountSetup?: { id: string };
	vpcSetup?: Pick<AifNetworks.Create, "vpc" | "subnets" | "onramp_region">;
	servicesSetup?: Pick<AifNetworks.Create, "services">;
};

const initialState: AifCreationFormState = {
	status: "idle",
	step: 0,
};

export const submitAifNetwork = createAsyncThunk.withTypes<{
	state: RootState;
}>()("submitAifNetwork", async (network: AifNetworks.Create, { getState }) => {
	const jwt = getState().login.jwt;
	try {
		const res = await aifApiClient.createNetwork(network, {
			headers: { Authorization: `Bearer ${jwt}` },
		});
		return res;
	} catch (err) {
		if (isAxiosError(err)) {
			if (err.response?.data?.message === NotSubscribedError) {
				throw new Error(err.response.data.message);
			}
		}
		throw err;
	}
});

export const AifCreationFormSlice = createSlice({
	name: "AifCreationForm",
	initialState,
	reducers: {
		setStep(state, action: PayloadAction<AifCreationFormState["step"]>) {
			state.step = action.payload;
		},
		stepForward(state) {
			state.step = Math.min(3, state.step + 1) as Step;
		},
		stepBack(state) {
			state.step = Math.max(0, state.step - 1) as Step;
		},
		setAccount(
			state,
			action: PayloadAction<
				Exclude<AifCreationFormState["accountSetup"], undefined>
			>,
		) {
			state.accountSetup = action.payload;
		},
		setVpc(
			state,
			action: PayloadAction<
				Exclude<AifCreationFormState["vpcSetup"], undefined>
			>,
		) {
			state.vpcSetup = action.payload;
		},
		setServices(
			state,
			action: PayloadAction<
				Exclude<AifCreationFormState["servicesSetup"], undefined>
			>,
		) {
			state.servicesSetup = action.payload;
		},
		reset(state) {
			// I do this separately to satisfy the linter, which doesn't believe we're using state by assigning to it
			state.step = initialState.step;
			state = initialState;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(submitAifNetwork.pending, (state) => {
				state.status = "loading";
				console.log("createAifNetwork pending");
			})
			.addCase(submitAifNetwork.fulfilled, (state, response) => {
				state.status = "idle";
				if (response.payload) {
					state.response = response.payload;
				}
				console.log("createAifNetwork fulfilled", state, response);
				window.location.href = "/aifabric/success";
			})
			.addCase(submitAifNetwork.rejected, (state, response) => {
				state.status = "idle";
				// We still need to relay errors
				state.error = response.error.message;
				console.log("createAifNetwork error", state.error, response);
			});
	},
});
export default AifCreationFormSlice.reducer;
export const {
	setAccount,
	setVpc,
	setServices,
	setStep,
	stepBack,
	stepForward,
	reset,
} = AifCreationFormSlice.actions;
