import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ocnApiClient, Status } from "../api";
import { OcnNetworks as AifNetworks } from "@stateless/aif-api";
import { RootState } from ".";
import { isAxiosError } from "axios";

type AifNetworksState = {
	status: Status;
	network: Partial<AifNetworks.Create>;
	error?: string;
};

const initialState: AifNetworksState = {
	network: {},
	status: "idle",
};

export const createNetworkThunk = createAsyncThunk.withTypes<{
	state: RootState;
}>();

export const createAifNetwork = createNetworkThunk(
	"createAifNetwork",
	async (network: AifNetworks.Create, { getState }) => {
		console.log(
			"Called create ocn network with",
			network,
			AifNetworks.Create.safeParse(network),
		);
		// TODO: why is this `unknown`? It _works_, but we don't have the right types here and I'm not sure why.
		// For the time being I'm just casting it.
		const jwt = getState().login.jwt;
		return ocnApiClient.createNetwork(network, {
			headers: { Authorization: `Bearer ${jwt}` },
		});
	},
);

const waitForDestructionOf = (networkId: string, jwt: string) =>
	new Promise<void>((resolve) => {
		const interval = setInterval(async () => {
			try {
				const net = await ocnApiClient.getNetwork({
					params: { id: networkId },
					headers: { Authorization: `Bearer ${jwt}` },
				});
				if (net.state === "Destroyed") {
					clearInterval(interval);
					resolve();
				}
			} catch (e: unknown) {
				if (isAxiosError(e) && e.status === 404) {
					clearInterval(interval);
					resolve();
				}
			}
		}, 10 * 1000);
	});

export const deleteAifNetwork = createNetworkThunk(
	"deleteAifNetwork",
	async (
		{ networkId, onSuccess }: { networkId: string; onSuccess: () => void },
		{ getState },
	) => {
		const jwt = getState().login.jwt;
		await ocnApiClient.deleteNetwork(undefined, {
			params: { id: networkId },
			headers: { Authorization: `Bearer ${jwt}` },
		});
		void waitForDestructionOf(networkId, jwt).then(onSuccess);
	},
);

export const AifNetworksSlice = createSlice({
	name: "AifNetworks",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(createAifNetwork.pending, (state) => {
				state.status = "loading";
				console.log("createAifNetwork pending");
			})
			.addCase(createAifNetwork.fulfilled, (state, response) => {
				state.status = "idle";
				if (response.payload) {
					state.network = response.payload;
				}
				console.log("createAifNetwork fulfilled", state, response);
				window.location.href = "/ocn/success";
			})
			.addCase(createAifNetwork.rejected, (state, response) => {
				state.status = "idle";
				state.error = response.error.message;
				console.log("createAifNetwork error", state, response);
			})
			.addCase(deleteAifNetwork.pending, (state) => {
				state.status = "loading";
				console.log("deleteAifNetwork pending");
			})
			.addCase(deleteAifNetwork.fulfilled, (state) => {
				state.status = "idle";
			})
			.addCase(deleteAifNetwork.rejected, (state, response) => {
				state.status = "idle";
				state.error = response.error.message;
				console.log("deleteAifNetwork error", state, response);
			});
	},
});
export default AifNetworksSlice.reducer;
