import logo from "../assets/stateless-mint-and-black.png";
import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { StatelessColors } from "../theme";
import { useAuth } from "oidc-react";

export default function Header() {
	const auth = useAuth();

	const logout = (): undefined => {
		console.log("Logging Out");
		auth.signOutRedirect();
	};

	return (
		<AppBar
			component="nav"
			sx={{ bgcolor: StatelessColors.white }}
			style={{ position: "relative", zIndex: 1301 }}
		>
			<Toolbar variant="dense">
				<img src={logo} alt="Stateless" width="150" />
				<Box sx={{ flexGrow: 1 }}>
					<Typography
						color="primary"
						variant="h5"
						component="div"
						style={{ paddingLeft: "20px" }}
					>
						AI Fabric
					</Typography>
				</Box>

				<Box sx={{ flexGrow: 0 }}>
					<Button
						variant="text"
						size="small"
						type="button"
						color="secondary"
						onClick={logout}
					>
						Logout
					</Button>
				</Box>
			</Toolbar>
		</AppBar>
	);
}
